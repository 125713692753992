import React, { useState } from 'react';
import ProductReview from '../../assets/images/Product Review.png';
import Assessment from '../../assets/images/Assessment.png';
import Review from '../../assets/images/Review.png';
import { Stepper, Step, Button, Typography } from '@material-tailwind/react';

const data = [
	{
		id: '1',
		title: 'Self Assessment',
		skill: 'C++',
		desc1: 'Describe a recent technical challenge you faced and how you overcame it.',
		desc2: 'What areas of programming do you feel you need to improve in? How do you plan to address these areas?',
	},
	{
		id: '2',
		title: 'Manager Review',
		skill: "Colleague's programming skills",
		desc1: 'Describe a situation where your colleague successfully solved a technical problem.',
		desc2: 'In which areas of programming do you think your colleague could improve?',
	},
	{
		id: '3',
		title: 'Peer Review',
		skill: "Team member's programming skills",
		desc1: 'Describe a recent technical challenge you faced and how you overcame it.',
		desc2: 'What areas of programming do you feel you need to improve in? How do you plan to address these areas?',
	},
];
function Checkpoints() {
	const checkBoxArray = Array.from({ length: 10 });
	const [selectedOption, setSelectedOption] = useState('option1');
	const [activeStep, setActiveStep] = React.useState(0);
	const [isLastStep, setIsLastStep] = React.useState(false);
	const [isFirstStep, setIsFirstStep] = React.useState(false);
	const handleNext = () => !isLastStep && setActiveStep((cur) => cur + 1);
	const handlePrev = () => !isFirstStep && setActiveStep((cur) => cur - 1);

	const handleOptionChange = (event) => {
		setSelectedOption(event.target.value);
	};
	return (
		<div className="p-5">
			<div className="md:flex block items-center justify-between  pb-[25.92px]">
				<h2 className="dark:text-white text-[#4b5563] text-[22px] font-bold leading-7 text-left">
					Time Until Next Checkpoint
				</h2>
				<p className="dark:text-[#BFC6D2] text-black text-sm md:mt-0 mt-3">
					Our CHECKPOINT process in Kanhasoft and then either "No
					CHECKPOINT
				</p>
			</div>
			<div className="w-full">
				<Stepper
					activeStep={activeStep}
					isLastStep={(value) => setIsLastStep(value)}
					isFirstStep={(value) => setIsFirstStep(value)}
					className="md:grid block grid-cols-3 gap-10"
				>
					<Step
						onClick={() => setActiveStep(0)}
						style={{
							background: 'transparent',
							width: 'auto',
							height: '100%',
						}}
					>
						<div className=" text-center w-full">
							<div
								className={`${
									activeStep === 0 ? 'bgActive' : 'bgInactive'
								} md:h-[170px] h-[125px] cursor-pointer flex items-center justify-between p-[21px] pr-[15px] mb-[10px] lg:mb-[39px] lg:mt-6 mt-[10px] rounded-[15px] shadow-none dark:shadow-[4px_4px_4px_0px_rgba(31,46,77,0.62)_inset]`}
							>
								<h2 className=" font-bold leading-7 text-left text-[19px] dark:text-white text-black">
									Self Assessment
								</h2>
								<img
									src={Assessment}
									alt="Assessment"
									className="md:w-auto w-[25%]"
								/>
							</div>
						</div>
					</Step>
					<Step
						onClick={() => setActiveStep(1)}
						style={{
							background: 'transparent',
							width: 'auto',
							height: '100%',
						}}
					>
						<div className=" text-center w-full">
							<div
								className={`${
									activeStep === 1 ? 'bgActive' : 'bgInactive'
								} md:h-[170px] h-[125px] cursor-pointer flex items-center justify-between p-[21px] pr-[15px] mb-[10px] lg:mb-[39px] lg:mt-6 mt-[10px] rounded-[15px] shadow-none dark:shadow-[4px_4px_4px_0px_rgba(31,46,77,0.62)_inset]`}
							>
								<h2
									color={activeStep === 1 ? 'blue' : 'gray'}
									className=" font-bold leading-7 text-left text-[19px] dark:text-white text-black"
								>
									Manager Review
								</h2>
								<img
									src={Review}
									alt="Review"
									className="md:w-auto w-[25%]"
								/>
							</div>
						</div>
					</Step>
					<Step
						onClick={() => setActiveStep(2)}
						style={{
							background: 'transparent',
							width: 'auto',
							height: '100%',
						}}
					>
						<div className=" text-center w-full">
							<div
								className={` ${
									activeStep === 2 ? 'bgActive' : 'bgInactive'
								} md:h-[170px] h-[125px] cursor-pointer flex items-center justify-between p-[21px] pr-[15px] mb-[10px] lg:mb-[39px] lg:mt-6 mt-[10px] rounded-[15px] shadow-none dark:shadow-[4px_4px_4px_0px_rgba(31,46,77,0.62)_inset]`}
							>
								<h2
									color={activeStep === 2 ? 'blue' : 'gray'}
									className=" font-bold leading-7 text-left text-[19px] dark:text-white text-black"
								>
									Peer Review
								</h2>
								<img
									src={ProductReview}
									alt="ProductReview"
									className="md:w-auto w-[25%]"
								/>
							</div>
						</div>
					</Step>
				</Stepper>
				<div className="text-white">
					{data.map((row, index) => (
						<>
							<div
								key={row.id}
								style={{
									display:
										activeStep === index ? 'block' : 'none',
								}}
							>
								<div className="bg-[#f2f2f2] dark:bg-[#17243E] lex items-center justify-between mb-[39px] p-[21px] pr-[15px] mt-6 lg:mt-0 rounded-[15px] shadow-none dark:shadow-[4px_4px_4px_0px_rgba(31,46,77,0.62)_inset]">
									<h2 className=" font-bold leading-7 text-left text-[19px] dark:text-white text-black">
										{row.title}
									</h2>
									<div className="md:grid block grid-cols-2 text-left md:pt-[48px] pt-[20px] border-b-[#2A3A5A] border-b border-solid pb-[18px] mb-[29px]">
										<h4 className="dark:text-white text-black">
											{row.skill}
										</h4>
										<div className="flex justify-end">
											<span className="text-[12px] dark:text-[#BFC6D2] text-black">
												Lowest
											</span>
											<span className="flex px-4 radio-button">
												{checkBoxArray.map((_, i) => (
													<>
														<div className="px-2">
															<input
																className="block"
																type="radio"
																id={i}
																name="radio-group"
																defaultChecked
															/>
															<label
																htmlFor={i}
															/>
															<div className="text-[14px] text-center mt-[5px] dark:text-[#BFC6D2] text-black">
																{i + 1}
															</div>
														</div>
													</>
												))}
											</span>
											<span className="text-[12px] dark:text-[#BFC6D2] text-black">
												Highest
											</span>
										</div>
									</div>
									<div>
										<Typography
											variant="h3"
											className="pb-[21px] text-[16px] text-left dark:text-white text-[#000] "
										>
											{row.desc1}
										</Typography>
										<textarea
											className="dark:bg-[#101a30] bg-white resize-none shadow-none border-none outline-none mt-1 p-2 block w-full rounded-md border-gray-300 focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
											rows="4"
										></textarea>
									</div>
									<div>
										<Typography
											variant="h3"
											className="pt-[21px] pb-[21px] text-[16px] text-left dark:text-white text-[#000] "
										>
											{row.desc2}
										</Typography>
										<textarea
											className="dark:bg-[#101a30] bg-white resize-none shadow-none border-none outline-none mt-1 p-2 block w-full rounded-md border-gray-300 focus:border-indigo-500 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
											rows="4"
										></textarea>
									</div>

									<div
										className="grid-cols-2 gap-5 mt-6"
										style={{
											display:
												activeStep === 2
													? 'grid'
													: 'none',
										}}
									>
										<Button
											onClick={handlePrev}
											disabled={isFirstStep}
											className="w-full bg-transparent p-4 rounded-md border border-gray-300 text-black dark:text-white"
										>
											Cancel
										</Button>
										<Button
											onClick={handleNext}
											disabled={isLastStep}
											className="w-full bg-button-gradiant cursor-pointer"
										>
											Confirm
										</Button>
									</div>
								</div>
							</div>
						</>
					))}
				</div>
				<div className="flex justify-between">
					<Button
						onClick={handlePrev}
						disabled={isFirstStep}
						className="bg-button-gradiant"
					>
						Prev
					</Button>
					<Button
						onClick={handleNext}
						disabled={isLastStep}
						className="bg-button-gradiant"
					>
						Next
					</Button>
				</div>
			</div>
		</div>
	);
}

export default Checkpoints;
