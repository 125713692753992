import React, { useState } from 'react';
import profileBg from '../../assets/images/profile-bg.png';
import Proicon1 from '../../assets/images/b1.png';
import Proicon2 from '../../assets/images/b2.png';
import Proicon3 from '../../assets/images/b3.png';
import Proicon4 from '../../assets/images/b4.png';
import Proicon5 from '../../assets/images/b5.png';
import Proicon6 from '../../assets/images/b6.png';
import { useSelector } from 'react-redux';
import { getDefaultProfile } from '../../component/Common';
import UserProfile from '../../component/ui-component/UserProfile';

import {
	Tabs,
	TabsHeader,
	TabsBody,
	Tab,
	TabPanel,
} from '@material-tailwind/react';

function Career() {
	const authUser = useSelector((state) => state.auth);

	const Iconlist = [
		{
			images: Proicon1,
		},
		{
			images: Proicon2,
		},
		{
			images: Proicon3,
		},
		{
			images: Proicon4,
		},
		{
			images: Proicon5,
		},
		{
			images: Proicon6,
		},
	];

	const [activeTab, setActiveTab] = useState('html');

	const data = [
		{
			label: 'Product Designer',
			value: 'html',
			title: 'Product Designer',
			TabCheckBox: [
				{ label: 'Communication' },
				{ label: 'Leadership' },
				{ label: 'Problemsolving' },
				{ label: 'Programming' },
				{ label: 'HTML' },
			],
			LanguagesAdd: [
				{ label: 'Communication 5' },
				{ label: 'Leadership 4' },
				{ label: 'Programming 7' },
				{ label: 'UI Design' },
				{ label: 'UX Design' },
				{ label: 'Wireframe' },
				{ label: 'HTML' },
			],
		},
		{
			label: 'Graphics Designer',
			value: 'react',
			title: 'Graphics Designer',
			TabCheckBox: [
				{ label: 'Communication' },
				{ label: 'Leadership' },
				{ label: 'Problemsolving' },
				{ label: 'Programming' },
			],
			LanguagesAdd: [
				{ label: 'Communication 5' },
				{ label: 'Leadership 4' },
				{ label: 'HTML' },
			],
		},
		{
			label: 'UX Researcher',
			value: 'vue',
			title: 'UX Researcher',
			TabCheckBox: [
				{ label: 'Communication' },
				{ label: 'Problemsolving' },
				{ label: 'Programming' },
				{ label: 'HTML' },
			],
			LanguagesAdd: [
				{ label: 'Communication 5' },
				{ label: 'Leadership 4' },
				{ label: 'Programming 7' },
				{ label: 'UI Design' },
				{ label: 'UX Design' },
				{ label: 'Wireframe' },
				{ label: 'HTML' },
			],
		},
		{
			label: 'Motion Designer',
			value: 'angular',
			title: 'Motion Designer',
			TabCheckBox: [
				{ label: 'Communication' },
				{ label: 'Leadership' },
				{ label: 'Problemsolving' },
			],
			LanguagesAdd: [
				{ label: 'UI Design' },
				{ label: 'UX Design' },
				{ label: 'Wireframe' },
				{ label: 'HTML' },
			],
		},
		{
			label: 'Marketing Designer',
			value: 'svelte',
			title: 'Marketing Designer',
			TabCheckBox: [
				{ label: 'Communication' },
				{ label: 'Leadership' },
				{ label: 'Problemsolving' },
				{ label: 'Programming' },
				{ label: 'HTML' },
			],
			LanguagesAdd: [
				{ label: 'Communication 5' },
				{ label: 'Leadership 4' },
				{ label: 'Programming 7' },
				{ label: 'UI Design' },
				{ label: 'HTML' },
			],
		},
	];

	return (
		<>
			<div className="px-5 pt-5">
				<div className="md:grid block grid-cols-12 gap-5 mb-10">
					<div className="col-span-4 md:mb-0 mb-4">
						<div className="user-profile rounded-2xl p-[45px] overflow-hidden dark:bg-[#1A2844] bg-[#fff] relative h-full dark:shadow-[4px_4px_4px_0px_rgba(31,46,77,0.62)_inset] shadow-[4px_4px_40px_0px_rgba(0,0,0,0.03),-4px_-4px_40px_0px_rgba(0,0,0,0.03)]">
							<img
								src={profileBg}
								alt="profileBg"
								className="absolute top-0 left-0 h-full w-full object-cover"
							/>

							<div className="relative">
								<div className="user text-center">
									<UserProfile
										imageUrl={
											authUser?.userDetails?.profile_image
										}
										defaultImageUrl={getDefaultProfile(
											authUser?.userDetails?.gender
										)}
										altName={
											authUser?.userDetails?.first_name
										}
										className={
											'h-[95px] w-[95px] rounded-full overflow-hidden border border-solid border-[#FFE334] inline-block'
										}
									/>
								</div>
								<h3 className="text-[22px] leading-7 font-bold mt-[18px] mb-3 dark:text-[#fff] text-[#0C1932]">
									{` ${authUser?.userDetails?.first_name} ${authUser?.userDetails?.last_name}`}
								</h3>
								<p className="text-sm leading-[18px] dark:text-[#BFC6D2] text-[#0C1932]">
									{`${authUser?.userDetails?.role}`}
								</p>
							</div>
						</div>
					</div>
					<div className="col-span-8">
						<div className="relative bg-[#6C38FF] rounded-[15px] text-left md:p-7 p-4 text-[#fff] h-full">
							<p>
								Ronnie, it's remarkable how you've helped build
								Kanhasoft for 4 years already. Moving from QA
								tester to producer in such a short amount of
								time, is outstanding. I see that you're working
								on Delegation and Review skills at the moment,
								to jump into the title of Executive Producer.
								Make sure to ask mentorship from Marten and
								maybe even a few books to read.
							</p>
							<ul className="flex items-center">
								{Iconlist.map((index, i) => (
									<li key={i} className="mt-[23px] mr-4">
										<img src={index.images} alt="img" />
									</li>
								))}
							</ul>
						</div>
					</div>
				</div>
				<div className="md:grid block grid-cols-12 gap-5">
					<div className="col-span-12">
						<h2 className="card-title dark:text-white text-[#4b5563]">
							Future Roles
						</h2>

						<Tabs value={activeTab}>
							<div className="career-tab md:flex block flex-wrap">
								<div className="careertab-menu md:w-[40%] w-full relative z-10">
									<TabsHeader>
										{data.map(({ label, value }) => (
											<Tab
												key={value}
												value={value}
												onClick={() =>
													setActiveTab(value)
												}
												className={
													activeTab === value
														? 'active-tab'
														: ''
												}
											>
												{label}
											</Tab>
										))}
									</TabsHeader>
								</div>

								<div className="career-tab-content md:w-[60%] w-full md:p-10 p-5">
									<TabsBody className="h-full">
										{data.map(
											({
												value,
												title,
												TabCheckBox,
												LanguagesAdd,
											}) => (
												<TabPanel
													key={value}
													value={value}
													className="p-0 h-full"
												>
													<h2 className="text-[#fff] text-[22px] leading-7 mb-7 text-left font-bold">
														{title}
													</h2>
													<div className="check-lang flex flex-col justify-between">
														<div className="flex flex-wrap tabcheckbox">
															{TabCheckBox.map(
																(check, id) => (
																	<label
																		key={id}
																		className="researcher-check mb-7 mr-[25px] pl-8 inline-block relative text-base font-semibold leading-[21px] text-[#fff] cursor-pointer "
																	>
																		<input
																			type="checkbox"
																			className="h-6 w-6 rounded absolute left-0 top-0 opacity-0"
																		/>
																		{
																			check.label
																		}
																		<span className="check h-6 w-6 rounded-full absolute left-0 top-0 border border-solid border-white bg-no-repeat bg-[center_center]"></span>
																	</label>
																)
															)}
														</div>
														<div className="languages flex flex-wrap -ml-[9px]">
															{LanguagesAdd.map(
																(index, i) => (
																	<span
																		key={i}
																		className="text-sm text-[#fff] px-[23px] py-[10px] font-semibold leading-[18px] border border-dashed border-white inline-block mb-5 mx-[9px] rounded-lg"
																	>
																		{
																			index.label
																		}
																	</span>
																)
															)}
														</div>
													</div>
												</TabPanel>
											)
										)}
									</TabsBody>
								</div>
							</div>
						</Tabs>
					</div>
				</div>
			</div>
		</>
	);
}

export default Career;
