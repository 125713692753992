import { createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

import {
	notificationSuccess,
	notificationFail,
} from '../slices/notificationSlice';
import { setLoading } from '../slices/dataLoadingSlice';
import { setMeetingData, setFetching } from '../slices/meetingSlice';

import apiClient from '../../config/apiClient';
import Messages from '../../utils/messages';
import { vsmMeeting } from '../../utils/validations';

export const getAllMeetingList = createAsyncThunk(
	'getAllMeetingList',
	async (_request = {}, { dispatch }) => {
		try {
			dispatch(setLoading(true));

			let { pageNo, pageSize, searchText, sortBy } = _request;
			let ordering = '';

			if (sortBy && sortBy.length > 0) {
				let sortField = sortBy.length > 0 ? sortBy[0].id : '';

				if (sortField !== 'active' && sortField !== 'action') {
					const sortOrder =
						sortBy.length > 0
							? sortBy[0].desc
								? `-${sortField}`
								: `${sortField}`
							: '';
					ordering = sortOrder ? `&ordering=${sortOrder}` : '';
				}
			}

			pageNo = pageNo || 1;
			pageSize = pageSize || 10;
			searchText = searchText ? `&search=${searchText}` : '';

			const response = await apiClient(false).get(
				`/accounts/meetings/?offset=${pageSize}&page=${pageNo}${searchText}${ordering}`
			);
			dispatch(setLoading(false));
			if (response?.data) {
				dispatch(setMeetingData(response?.data));
			} else {
				dispatch(setFetching(false));
				toast.error(Messages.ERROR.DEFAULT);
			}
		} catch (err) {
			dispatch(setLoading(false));
			toast.error(err?.response?.data?.error || Messages.ERROR.DEFAULT);
		}
	}
);

export const createMeeting = createAsyncThunk(
	'createMeeting',
	async (_request, { dispatch }) => {
		try {
			const formData = new FormData();

			// Append other form data fields
			Object.keys(_request).forEach((key) => {
				if (
					key !== 'files' &&
					key !== 'users' &&
					_request[key] !== null
				) {
					formData.append(key, _request[key]);
				}
			});

			if (_request.users.length > 0 && _request.users) {
				_request.users.map((item) => formData.append('users', item));
			}

			// Append file data if provided
			if (_request.files) {
				formData.append('files', _request.files);
			}

			dispatch(setLoading(true));
			const response = await apiClient(true).post(
				`/accounts/meetings/`,
				formData
			);
			dispatch(setLoading(false));
			if (response?.data) {
				dispatch(getAllMeetingList());
				dispatch(notificationSuccess(vsmMeeting?.addSucces));
				toast.success(vsmMeeting?.addSucces);
				_request.callbackFunction();
			} else {
				let msg =
					'Failed to login using this credentials' ||
					Messages.ERROR.DEFAULT;
				dispatch(notificationFail(msg));
				toast.error(msg);
			}
		} catch (err) {
			dispatch(setLoading(false));
			dispatch(notificationFail(err?.message || Messages.ERROR.DEFAULT));
			toast.error(err?.message);
		}
	}
);

export const updateMeeting = createAsyncThunk(
	'updateMeeting',
	async (_request, { dispatch }) => {
		try {
			const formData = new FormData();
			const { is_from_events } = _request;
			delete _request.is_from_events;

			Object.keys(_request).forEach((key) => {
				if (
					key !== 'files' &&
					key !== 'users' &&
					_request[key] !== null
				) {
					formData.append(key, _request[key]);
				}
			});

			if (_request.users) {
				_request.users.map((item) => formData.append('users', item));
			}

			// Append file data if provided
			if (_request.files) {
				formData.append('files', _request.files);
			}

			// formData.forEach((value, key) => {
			//   console.log(`${key}: ${value}`);
			// });

			dispatch(setLoading(true));
			const response = await apiClient(true).patch(
				`/accounts/meetings/${_request.id}/`,
				formData
			);

			dispatch(setLoading(false));
			if (response?.data) {
				dispatch(getAllMeetingList());
				if (!is_from_events) {
					dispatch(notificationSuccess(response?.data?.message));
					toast.success(vsmMeeting?.updateSuccess);
					_request.callbackFunction();
				}
			} else {
				let msg =
					'Failed to login using this credentials' ||
					Messages.ERROR.DEFAULT;
				dispatch(notificationFail(msg));
				toast.error(msg);
			}
		} catch (err) {
			dispatch(setLoading(false));
			dispatch(notificationFail(err?.message || Messages.ERROR.DEFAULT));
			toast.error(err?.message);
		}
	}
);

export const removeMeeting = createAsyncThunk(
	'removeMeeting',
	async (_request, { dispatch }) => {
		try {
			dispatch(setLoading(true));
			await apiClient(false).delete(`/accounts/meetings/${_request.id}/`);
			dispatch(setLoading(false));
			dispatch(getAllMeetingList());
			dispatch(notificationSuccess(vsmMeeting?.deleteSuccess));
			toast.success(vsmMeeting?.deleteSuccess);
			// _request.callbackFunction();
		} catch (err) {
			dispatch(setLoading(false));
			dispatch(notificationFail(err?.message || Messages.ERROR.DEFAULT));
			toast.error(err?.message);
		}
	}
);

// export const updateLeaveStatus = createAsyncThunk(
//   "updateLeaveStatus",
//   async (_request, { dispatch }) => {
//     dispatch(setLoading(true));
//     const { user_data } = _request;
//     delete _request.user_data;
//     let is_approval_list = "";
//     if (!user_data?.is_companyadmin) {
//       is_approval_list = `?apleaves=${true}`;
//     }
//     const response = await apiClient().patch(
//       `/accounts/meetings/${_request.id}/${is_approval_list}`,
//       _request
//     );

//     dispatch(setLoading(false));
//     if (response?.data) {
//       if (user_data?.is_companyadmin) {
//         dispatch(getAllMeetingList());
//       } else {
//         dispatch(getAllMeetingList({ approvalLeave: true }));
//       }
//       dispatch(notificationSuccess(response?.data?.message));
//       toast.success(vsmMeeting?.leaveStatus);
//     } else {
//       let msg =
//         "Failed to login using this credentials" || Messages.ERROR.DEFAULT;
//       dispatch(notificationFail(msg));
//     }
//   }
// );
